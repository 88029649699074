
@media screen and (max-width: 768px){
    .hide-on-mobile {
        display: none !important;
    }
  }
  @media screen and (min-width: 768px){
    .show-on-mobile {
        display: none !important;
    }
  }